export default {
  categories: {
    items: {
      text: {
        'ui:widget': 'EditorMarkdownWidget',
      },
      color: {
        'ui:widget': 'BaseColorWidget',
      },
      tickets: {
        items: {
          value: {
            'ui:widget': 'EditorTicketWidget',
          },
        },
      },
    },
  },
}
