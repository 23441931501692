<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-2xl"
    :class="formData.blockShadow && 'shadow-lg'"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
  >
    <div
      class="flex justify-between items-center mb-3 md:mb-4 px-1 md:px-0 relative"
    >
      <h2
        v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
        class="font-bold grow"
        :class="titleSizeMap[formData.titleSize]"
        :style="{ textAlign: formData.titleAlign }"
      >
        {{ formData.title }}
      </h2>
      <BaseLink
        v-if="viewAllLink && viewAllText"
        class="absolute right-0"
        weight="normal"
        :to="viewAllLink"
        >{{ viewAllText }}</BaseLink
      >
    </div>
    <div v-if="isLoading" class="p-24 flex justify-center">
      <BaseSpinner />
    </div>
    <template v-else>
      <div class="gap-6 hidden md:grid grid-cols-2 lg:grid-cols-4">
        <template
          v-for="(event, index) in formData.events"
          :key="'event-grid' + index"
        >
          <EventsCard
            v-if="eventsStore.eventsMap[event.eventId]"
            :event="eventsStore.eventsMap[event.eventId]"
          />
        </template>
      </div>
      <div class="block md:hidden">
        <Swiper
          :slides-per-view="1.2"
          :space-between="SLIDES_GAP"
          :pagination="false"
          :modules="swiperModules"
          :class="formData.isShadowed && 'swiper-shadowed'"
        >
          <template
            v-for="(event, index) in formData.events"
            :key="'event-slider' + index"
          >
            <SwiperSlide
              v-if="eventsStore.eventsMap[event.eventId]"
              :style="{
                padding: '0 4px',
                marginRight: SLIDES_GAP + 'px',
                width: getSlideWidth(formData.colsNumberOnMobile, SLIDES_GAP),
              }"
            >
              <EventsCard :event="eventsStore.eventsMap[event.eventId]" />
            </SwiperSlide>
          </template>
        </Swiper>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Grid, Pagination } from 'swiper/modules'
import EventsCard from './EventsCard.vue'
import { getSlideWidth } from '~/components/base/utils'
import 'swiper/css'
import 'swiper/css/grid'
import 'swiper/css/pagination'
import { usePageEditor } from '~/stores/page-editor'
import { titleSizeMap } from '~/components/page-editor/common'

const pageEditorStore = usePageEditor()
const eventsStore = useEvents()
const authStore = useAuth()
const isLoading = ref(false)

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = defineProps<PropsInterface>()
const SLIDES_GAP = 24

const swiperModules = [Grid, Pagination]

async function load() {
  isLoading.value = true
  await eventsStore.fetchEventsByIds(
    props.formData.events.map((event: any) => event.eventId),
    !!authStore.auth
  )
  isLoading.value = false
}

const viewAllText = computed(() => props.formData.viewAllText?.trim() || '')
const viewAllLink = computed(() => {
  return props.formData.viewAllLink?.trim() || ''
})

watch(
  () => props.formData,
  () => {
    load()
  },
  { deep: true }
)

onServerPrefetch(async () => {
  await load()
})

onMounted(async () => {
  await load()
})
</script>
