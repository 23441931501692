import { DEFAULT_IMAGE_SQUARE } from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  bgColorSchema,
  shadowSchema,
  showAllSettingsSchema,
  textColorSchema,
  titlePropertiesSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Images',
  description: 'Simple block with a configurable number of images per line',
  type: 'object',
  required: [
    'colsNumberOnDesktop',
    'colsNumberOnMobile',
    // 'rowsNumberInSliderOnMobile', // TODO: implement rows avoiding swiper grip option
  ],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: [
      'textColor',
      'bgColor',
      'blockShadow',
      'colsNumberOnDesktop',
      'colsNumberOnMobile',
      'isUseSliderOnMobile',
      'isOpenFullscreen',
    ],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    ...textColorSchema,
    ...bgColorSchema,
    ...shadowSchema,
    colsNumberOnDesktop: {
      title: 'Number of cols on desktop',
      type: 'number',
      default: 4,
      minimum: 1,
      maximum: 6,
    },
    colsNumberOnMobile: {
      title: 'Number of cols on mobile',
      type: 'number',
      default: 2,
      minimum: 1,
      maximum: 6,
    },
    isUseSliderOnMobile: {
      title: 'Use slider on mobile',
      type: 'boolean',
      default: true,
    },
    isOpenFullscreen: {
      title: 'Open images in fullscreen gallery',
      type: 'boolean',
      default: true,
    },
    // rowsNumberInSliderOnMobile: {
    //   title: 'Number of rows in slider on mobile',
    //   type: 'number',
    //   default: 1,
    //   minimum: 1,
    //   maximum: 4,
    //   'ui:hidden': '{{parentFormData.isUseSliderOnMobile === false}}',
    // },
    images: {
      type: 'array',
      title: 'A list of images',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Image url',
        type: 'string',
        default: DEFAULT_IMAGE_SQUARE,
      },
    },
  },
}
