<template>
  <div
    :id="uuid"
    ref="programBlock"
    :class="[!isInIframe && 'rounded-xl', isOnGeneral && 'bg-white']"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
  >
    <div v-if="!formData.programId || !formData.eventId" class="p-6 md:p-8">
      <div class="font-bold text-2xl mb-2">{{ $t('program.program') }}</div>
      <div v-if="!formData.eventId">
        {{ $t('program.no_event_id') }}
      </div>
      <div v-else>
        {{ $t('program.select_program') }}
      </div>
    </div>
    <div v-else>
      <div v-if="!isLoaded" class="p-12 flex justify-center">
        <BaseSpinner />
      </div>
      <div
        v-else-if="program"
        class="relative text-trueGray-900 p-6 md:p-8"
        :class="[isOnHub && 'hub min-h-screen', !isOnGeneral && 'program']"
      >
        <div class="max-w-screen-2xl mx-auto flex flex-wrap gap-8">
          <div class="w-full flex flex-wrap content-start gap-6 sm:gap-8">
            <div
              v-if="!(formData.hideOnEmbed && isInIframe)"
              class="w-full flex flex-col gap-3 xl:gap-6"
            >
              <h2 class="text-2xl xl:text-3xl font-bold py-2">
                {{ program.title }}
              </h2>
              <h1
                v-if="program.description"
                class="text-3xl md:text-4xl xl:text-5xl font-bold"
              >
                {{ program.description }}
              </h1>
            </div>
            <ProgramDownload
              :program="program"
              :lang="formData.pdfLang"
              :border-color="textColor"
            />
            <div
              v-if="activeStream?.sponsors.length"
              class="w-full hidden md:block"
            >
              <ProgramSponsors
                :sponsors="activeStream.sponsors"
                :stream-title="activeStream.title"
                :is-on-hub="isOnHub"
                @open-entity="openEntity"
              />
            </div>
          </div>
          <div
            class="w-full bg-white text-trueGray-800 rounded-3xl flex flex-col sm:gap-10"
            :class="!isOnGeneral && backgroundColor !== 'white' && 'p-4'"
          >
            <div v-if="daysOptions.length > 1">
              <BaseSelect
                v-model="activeDayId"
                class="sm:hidden text-black mb-4"
                name="activeDayId"
                background-class="bg-gray-100"
                border-class="border-none"
                :options="daysOptions"
              />
              <div class="hidden sm:block">
                <div>
                  <nav
                    class="flex flex-wrap justify-center gap-4"
                    aria-label="Tabs"
                  >
                    <button
                      v-for="option in daysOptions"
                      :key="option.value"
                      class="py-1.5 px-12 rounded-xl text-center border border-gray-400 flex items-center duration-150 hover:border-primary-800"
                      :class="[
                        option.value === activeDayId &&
                          'text-white border-primary-800 bg-primary-800',
                        !isOnGeneral &&
                          (option.value === activeDayId
                            ? 'day-button-active'
                            : 'day-button'),
                      ]"
                      style="min-height: 40px"
                      @click="activeDayId = option.value"
                    >
                      <span class="text-md">
                        {{ option.label }}
                      </span>
                    </button>
                  </nav>
                </div>
              </div>
            </div>

            <div v-if="activeDay">
              <div v-if="activeDay.streams.length > 1">
                <BaseSelect
                  v-model="activeStreamId"
                  class="sm:hidden text-black mb-8"
                  background-class="bg-gray-100"
                  border-class="border-none"
                  name="activeStreamId"
                  :options="streamsOptions"
                />
                <div class="hidden sm:block">
                  <nav class="flex flex-wrap justify-center" aria-label="Tabs">
                    <button
                      v-for="stream in activeDay.streams"
                      :key="stream.id"
                      class="py-2 px-1 pb-3 flex items-center font-medium mx-3 border-b-2 duration-150"
                      :class="[
                        activeStreamId === stream.id
                          ? 'border-primary-800 text-primary-900'
                          : 'text-trueGray-400 border-trueGray-300 hover:border-primary-800',
                        !isOnGeneral &&
                          (activeStreamId === stream.id
                            ? 'stream-button-active'
                            : 'stream-button'),
                      ]"
                      @click="activeStreamId = stream.id"
                    >
                      <span
                        class="w-4 h-4 mr-2.5 rounded-full bg-gray-300"
                        :class="
                          activeStreamId === stream.id && 'bg-primary-700'
                        "
                        :style="{
                          backgroundColor:
                            activeStreamId === stream.id && !isOnGeneral
                              ? accentColor
                              : undefined,
                        }"
                      />
                      <span>{{ stream.title }}</span>
                    </button>
                  </nav>
                </div>
              </div>

              <div class="flex items-center space-x-2 my-4">
                <BaseIcon name="outline_clock"></BaseIcon>
                <h3 class="text-md font-medium">
                  <span v-if="program.timezone_label">
                    {{ program.timezone_label }}
                  </span>
                  <span v-else>
                    UTC{{
                      timeZoneOffset > 0 ? '+' + timeZoneOffset : timeZoneOffset
                    }}
                  </span>
                </h3>
              </div>

              <div v-if="activeStream" class="flex flex-col gap-6">
                <template
                  v-for="section in activeStream?.sections"
                  :key="section.id"
                >
                  <ProgramSection
                    :section="section"
                    :timezone="program.timezone"
                    :template="program.template"
                    :is-on-hub="isOnHub"
                    :is-on-general="isOnGeneral"
                    :is-us="!!props.formData.isUsDateFormat"
                    :is-in-iframe="isInIframe"
                    :day-date="activeDay.date"
                    :event-id="formData.eventId"
                    :is-hub-open="eventsStore.isHubOpen(eventsStore.event)"
                    @add-to-calendar="addToCalendar(section.id)"
                    @open-entity="openEntity"
                    @update="emit('update')"
                    @update:favorite="
                      (type, status) =>
                        onUpdateFavorite(type, status, section.title)
                    "
                  />
                </template>
              </div>
            </div>
          </div>
          <div v-if="activeStream?.sponsors.length" class="md:hidden w-full">
            <ProgramSponsors
              :sponsors="activeStream.sponsors"
              :stream-title="activeStream.title"
              :is-on-hub="isOnHub"
              @open-entity="openEntity"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ProgramSponsors from './components/ProgramSponsors.vue'
import ProgramDownload from './components/ProgramDownload.vue'
import ProgramSection from './components/ProgramSection.vue'
import type { ProgramDay, ProgramStream, HrefType } from '~/models/program'
import { useProgram } from '~/stores/program'
import { useEvents } from '~/stores/events'
import { removeMarkdown } from '~/utils/remove-markdown'
import { EntityType } from '~/models/common'
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()

interface PropsInterface {
  uuid: string
  formData: {
    eventId: number
    programId: number
    isUsDateFormat?: boolean
    pdfLang?: string
    hideOnEmbed?: boolean
  }
  isInIframe?: boolean
}

const props = withDefaults(defineProps<PropsInterface>(), {
  isInIframe: false,
})

const emit = defineEmits(['update'])

const programStore = useProgram()
const eventsStore = useEvents()
const route = useRoute()
const nuxtApp = useNuxtApp()

const programBlock = ref<HTMLElement | null>(null)
const activeDay = ref<ProgramDay | undefined>(undefined)
const activeStream = ref<ProgramStream | undefined>(undefined)
const { $dayjs } = useNuxtApp()

const isOnHub = computed(() => {
  return route.query.target === 'hub'
})

const isOnGeneral = computed(() => !isOnHub.value && !props.isInIframe)

const isLoaded = computed(() => {
  return programStore.programMap[props.formData.programId]?.isLoaded
})

const program = computed(() => {
  return programStore.programMap[props.formData.programId]?.program
})

const activeDayId = computed({
  get() {
    return activeDay.value?.id || program.value?.days[0].id
  },
  set(dayId: number | undefined) {
    const day = program.value?.days.find((day) => day.id === dayId)
    activeDay.value = day
    activeStream.value = day?.streams[0]
  },
})

const activeStreamId = computed({
  get() {
    return activeStream.value?.id || program.value?.days[0]?.streams[0]?.id
  },
  set(streamId: number | undefined) {
    activeStream.value = activeDay.value?.streams.find(
      (stream) => stream.id === streamId
    )
  },
})

const timeZoneOffset = computed(() => {
  if (program.value?.timezone) {
    return $dayjs().tz(program.value.timezone).utcOffset() / 60 // offset is in minutes
  }

  return 0
})

// dates in program are in the program timezone, not in utc
const getDayDateFormatted = (date: string) => {
  return $dayjs
    .tz(date, program.value?.timezone)
    .format(props.formData.isUsDateFormat ? 'MM/DD/YY' : 'DD.MM.YYYY')
}

const daysOptions = computed(() => {
  return (program.value?.days || []).map((day) => ({
    value: day.id,
    label: `${day.title} (${getDayDateFormatted(day.date)})`,
  }))
})

const streamsOptions = computed(() => {
  return (activeDay.value?.streams || []).map((stream) => ({
    value: stream.id,
    label: stream.title,
  }))
})

const backgroundColor = computed(() => {
  return (
    (isOnHub.value
      ? program.value?.template?.params.hub.backgroundColor
      : program.value?.template?.params.backgroundColor) || 'white'
  )
})

const textColor = computed(() => {
  return (
    (isOnHub.value
      ? program.value?.template?.params.hub.textColor
      : program.value?.template?.params.textColor) || 'black'
  )
})

const accentColor = computed(() => {
  return (
    (isOnHub.value
      ? program.value?.template?.params.hub.accentColor
      : program.value?.template?.params.accentColor) || 'black'
  )
})

const eventHost = computed(() => {
  return (
    eventsStore.event?.host ||
    `https://wnhub.io/events/${eventsStore.event?.slug}`
  )
})

function openEntity(
  entityType: string,
  hrefType: HrefType,
  hrefId: number,
  hrefLink: string
) {
  if (!isOnHub.value) {
    if (hrefType !== 'outer' && hrefId) {
      const url = `${eventHost.value}/${
        entityType === 'company' ? 'companies' : 'members'
      }/${hrefId}`
      window.open(url, '_blank')
      return
    } else if (hrefLink) {
      window.open(hrefLink, '_blank')
      return
    }

    return
  }

  let type = ''
  let value = '' as string | number

  if (hrefType === 'outer') {
    type = 'outer'
    value = hrefLink
  } else {
    type = entityType
    value = hrefId
  }

  if (type && value && props.isInIframe) {
    window.parent &&
      window.parent.postMessage(
        { message_type: 'entity', entity: { type, value } },
        '*'
      )
  }
}

function onUpdateFavorite(
  type: 'add' | 'remove',
  isSuccess: boolean,
  title: string
) {
  if (props.isInIframe && window.parent) {
    window.parent.postMessage(
      {
        message_type: 'favorite',
        type,
        isSuccess,
        entity: {
          type: EntityType.ProgramSection,
          title,
        },
      },
      '*'
    )
  }
}

function escape(string: string) {
  return encodeURIComponent(string).replace(/[!'()*]/g, (c) => {
    return '%' + c.charCodeAt(0).toString(16)
  })
}

function getGoogleDate(date: string) {
  return $dayjs
    .tz(date, program.value?.timezone)
    .utc()
    .format('YYYYMMDDTHHmmss[Z]')
}

function addToCalendar(sectionId: number) {
  if (!activeDayId.value || !activeStreamId.value) {
    return
  }

  const section = activeStream.value?.sections.find(
    (section) => section.id === sectionId
  )

  if (!section) {
    return
  }

  const { start, end, title, description } = section

  const startDate = getGoogleDate(`${activeDay.value?.date} ${start}`)
  const endDate = getGoogleDate(`${activeDay.value?.date} ${end}`)

  const dates = escape(`${startDate}/${endDate}`)
  const simpleDescription = description
    ? removeMarkdown(description, {
        listUnicodeChar: '-',
      })
    : ''

  const url = `https://www.google.com/calendar/render?action=TEMPLATE&text=${escape(
    title || ''
  )}&details=${escape(simpleDescription || '')}&location=${escape(
    activeStream.value?.title || ''
  )}&dates=${dates}`

  window.open(url, '_blank')
}

const setActiveDayAndStream = () => {
  if (program.value) {
    const currentDayId = program.value.current_day_id

    activeDay.value =
      program.value.days.find((day) => day.id === currentDayId) ||
      program.value.days[0]
    activeStream.value = activeDay.value?.streams[0]
  }
}

const load = async () => {
  if (props.formData.eventId && props.formData.programId) {
    await programStore.fetchProgram(
      props.formData.eventId,
      props.formData.programId
    )
    await eventsStore.fetchEvent(props.formData.eventId)
  }
}

onServerPrefetch(async () => {
  await load()
  setActiveDayAndStream()
})

onMounted(async () => {
  await load()
  setActiveDayAndStream()
})

onBeforeMount(() => {
  if (nuxtApp.isHydrating) {
    setActiveDayAndStream()
  }
})

onUpdated(() => {
  nextTick(() => {
    emit('update')
  })
})

watch(
  () => props.formData.programId,
  async () => {
    await load()
    setActiveDayAndStream()
  }
)
</script>

<style lang="scss" scoped>
.program {
  background-color: v-bind(backgroundColor);
  color: v-bind(textColor);
}

.day-button:hover,
.stream-button:hover {
  border-color: v-bind(accentColor);
}

.day-button-active {
  color: #ffffff;
  border-color: v-bind(accentColor);
  background-color: v-bind(accentColor);
}

.stream-button-active {
  color: v-bind(accentColor);
  border-color: v-bind(accentColor);
}
</style>
