export const useEditorLinks = () => {
  const route = useRoute()
  const host = useHost()
  const { pushNotification } = useNotifications()

  const getBlockLink = (hash: string) => {
    return `${host}${route.path}#${hash}`
  }

  function scrollToHash(hash: string) {
    const element = document.querySelector(`#${hash}`)
    if (!element) {
      pushNotification({
        title: 'No block with such hash on the page',
        theme: 'destructive',
      })
      return
    }
    element.scrollIntoView({ behavior: 'smooth' })
  }

  return {
    getBlockLink,
    scrollToHash,
  }
}
