<template>
  <div
    :id="uuid"
    class="px-6 py-3 xl:px-0"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold mb-4 md:mb-6"
      :style="{ textAlign: formData.titleAlign }"
    >
      {{ formData.title }}
    </h2>
    <div
      class="grid grid-cols-2 xxs:grid-cols-3 md:grid-cols-[repeat(auto-fit,_minmax(50px,_1fr))] gap-3 lg:gap-3"
    >
      <template v-for="(tile, index) in formData.tiles" :key="'grid' + index">
        <BaseLink
          :to="getInternalPath(tile.link)"
          target="_blank"
          theme="none"
          class="hover:opacity-80"
          :href="tile.link"
        >
          <div
            class="bg-white flex gap-2 xl:gap-1.5 flex-col max-w-full xl:flex-row xl:h-[80px] items-center justify-between flex-1 xl:flex-nowrap p-3 xl:p-4 xl:pl-3 xl:pr-2.5 rounded-2xl aspect-square xl:aspect-auto"
            :class="formData.blockShadow && 'shadow-lg'"
          >
            <div
              class="xl:w-[50px] xl:h-full xl:shrink-0 shrink xl:order-last w-[60%] flex-grow xl:flex-grow-0 flex items-center justify-center bg-no-repeat bg-contain bg-center"
              :style="`background-image: url(${tile.imageUrl})`"
            ></div>
            <div
              class="text-md w-full xl:w-auto lg:text-lg font-bold !leading-tight line-clamp-2 md:line-clamp-3 break-words text-center xl:text-left"
            >
              {{ tile.title }}
            </div>
          </div>
        </BaseLink>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getInternalPath } from '~/components/base/utils'
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}
defineProps<PropsInterface>()
</script>
