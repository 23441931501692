<template>
  <div
    :id="uuid"
    class="p-6 md:p-8 bg-white rounded-xl"
    :class="[formData.blockShadow && 'shadow-lg']"
    :style="{
      color: formData.textColor,
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
    }"
  >
    <h2
      v-if="formData.title && !(formData.hideOnEmbed && isInIframe)"
      class="text-2xl md:text-3xl font-bold"
      :style="{ textAlign: formData.titleAlign }"
      :class="!formData.image && 'md:mb-2'"
    >
      {{ formData.title }}
    </h2>
    <div class="flex gap-8">
      <div v-if="formData.image" class="w-1/3 mt-4 hidden md:block">
        <div>
          <img
            :src="formData.image"
            class="w-full rounded-xl overflow-hidden"
          />
        </div>
      </div>
      <div class="flex-1 flex" :class="!formData.image && formData.align">
        <div
          class="flex-1 w-full flex flex-col divide-y divide-gray-200"
          :style="{
            maxWidth:
              !formData.image &&
              formData.align !== 'w-full' &&
              formData.maxWidth
                ? `${formData.maxWidth}px`
                : '',
          }"
        >
          <div
            v-for="(tab, index) in formData.tabs"
            :key="tab.label + index"
            class="pt-3 pb-1"
          >
            <div
              class="text-lg flex items-center justify-between cursor-pointer gap-2"
              @click="toggleTab(index)"
            >
              <div>
                <span v-if="formData.numeration">
                  {{ (index + 1).toString().padStart(2, '0') }}.
                </span>
                {{ tab.label }}
              </div>
              <div>
                <BaseIcon
                  :name="isOpen(index) ? 'outline_minus' : 'outline_plus'"
                />
              </div>
            </div>
            <transition
              enter-active-class="transition-all duration-200"
              enter-from-class="transform -translate-y-3 opacity-0"
              enter-to-class="transform translate-y-0 opacity-100"
              leave-active-class="transition-all duration-200"
              leave-from-class="transform translate-y-0"
              leave-to-class="transform -translate-y-3 opacity-0"
            >
              <div v-show="isOpen(index)" class="overflow-hidden">
                <div
                  class="tab-text marked text-sm py-1"
                  :class="formData.numeration && 'pl-7'"
                  v-html="
                    getMarkedTextWithBlankLinks(tab.text, formData.linkColor)
                  "
                />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { usePageEditor } from '~/stores/page-editor'

const pageEditorStore = usePageEditor()

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

defineProps<PropsInterface>()

const { getMarkedTextWithBlankLinks } = useMarkedText()

const openTabs = ref<number[]>([])

const toggleTab = (index: number) => {
  if (openTabs.value.includes(index)) {
    openTabs.value = openTabs.value.filter((i) => i !== index)
  } else {
    openTabs.value.push(index)
  }
}

const isOpen = (index: number) => {
  return openTabs.value.includes(index)
}
</script>

<style lang="scss">
.tab-text.marked {
  h1 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  h2 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>
