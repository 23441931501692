<template>
  <n-config-provider preflight-style-disabled>
    <n-switch v-model:value="value">
      <template #checked> Hide settings </template>
      <template #unchecked> Show settings </template>
    </n-switch>
  </n-config-provider>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: String,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])

const value = computed({
  get: () => {
    return !!props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value ? 'true' : undefined)
  },
})
</script>
