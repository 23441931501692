<template>
  <BaseLink
    v-if="isExternalLink && button.url"
    :href="getLinkUrl(button)"
    :target="getLinkTarget(button)"
    class="hover:brightness-110 duration-150"
  >
    <BaseButton
      v-if="button.type === 'button'"
      look="none"
      :style="getButtonStyle(button)"
    >
      {{ button.text }}
    </BaseButton>
    <span v-else :style="{ color: button.color }">
      {{ button.text }}
    </span>
  </BaseLink>
  <template v-if="!isExternalLink && button.blockHash">
    <BaseLink
      v-if="button.isBlank"
      :href="getBlockLink(button.blockHash)"
      target="_blank"
      class="hover:brightness-110 duration-150"
    >
      <BaseButton look="none" :style="getButtonStyle(button)">
        {{ button.text }}
      </BaseButton>
    </BaseLink>
    <BaseButton
      v-else
      look="none"
      class="hover:brightness-110 duration-150"
      :style="getButtonStyle(button)"
      @click="scrollToHash(button.blockHash)"
    >
      {{ button.text }}
    </BaseButton>
  </template>
</template>

<script setup lang="ts">
import { getButtonStyle, type ButtonInterface } from './common'

interface PropsInterface {
  button: ButtonInterface
}

const { isApp } = useDetect()
const { wnhubPrefix, wnhubEventPrefix } = useRuntimeConfig().public
const props = defineProps<PropsInterface>()

const { getBlockLink, scrollToHash } = useEditorLinks()

// for already existing buttons where the isExternalLink is undefined
const isExternalLink = computed(() => {
  return props.button.isExternalLink ?? true
})

function getLinkTarget(button: ButtonInterface) {
  return isApp &&
    (button.url.startsWith(wnhubEventPrefix) ||
      button.url.startsWith(wnhubPrefix))
    ? '_self'
    : '_blank'
}

function getLinkUrl(button: ButtonInterface) {
  if (!isApp) {
    return button.url
  }

  if (button.url.startsWith(wnhubEventPrefix)) {
    return `/eventhub/index.html?redirect=${button.url.split(wnhubPrefix)[1]}`
  }

  if (button.url.startsWith(wnhubPrefix)) {
    return `${button.url.split(wnhubPrefix)[1]}`
  }

  return button.url
}
</script>
