import { basePropertiesSchema } from '../base-schema-properties'
import { shadowSchema, titlePropertiesSchema, titleSizeSchema } from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Latest News',
  description: 'Block with list of news',
  type: 'object',
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...titleSizeSchema,
    ...shadowSchema,
    viewAllLink: {
      title: 'View all link (only internal link, for example: /news)',
      type: 'string',
      default: '/news',
    },
    viewAllText: {
      title: 'View all text',
      type: 'string',
      default: 'View all',
    },
  },
}
