import { DEFAULT_TEXT } from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'

const navigationItemsConfig = {
  title: 'Item',
  type: 'object',
  required: ['title', 'blockHash', 'externalLink'],
  properties: {
    title: {
      title: 'Title',
      type: 'string',
      default: DEFAULT_TEXT,
    },
    isExternalLink: {
      title: 'Is external link',
      type: 'boolean',
      default: false,
    },
    blockHash: {
      title: 'Editor block',
      type: 'string',
      description:
        'To make it easier to find the block here, first fill in the field "In-page block id"',
      'ui:hidden': '{{ parentFormData.isExternalLink === true }}',
    },
    externalLink: {
      title: 'External link',
      type: 'string',
      'ui:hidden': '{{ parentFormData.isExternalLink === false }}',
    },
    isBlank: {
      title: 'Is open in new window',
      type: 'boolean',
      default: false,
      'ui:hidden': '{{ parentFormData.isExternalLink === true }}',
    },
  },
}

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Menu',
  description: 'Customizable menu with internal and external links',
  type: 'object',
  properties: {
    ...basePropertiesSchema,
    menuStyle: {
      title: 'Menu style',
      type: 'string',
      default: 'bg',
      enum: ['separate', 'bg'],
      enumNames: ['Separate buttons', 'All together on a white background'],
    },
    sticky: {
      title: 'Sticky to top',
      type: 'boolean',
      default: true,
    },
    stickyShadow: {
      title: 'Cast shadow when stuck',
      type: 'boolean',
      default: true,
    },
    items: {
      type: 'array',
      title: 'A list of menu items',
      'ui:options': {
        showIndexNumber: true,
      },
      minItems: 1,
      items: { ...navigationItemsConfig },
    },
    buttons: {
      type: 'array',
      title: 'A list of buttons',
      'ui:options': {
        showIndexNumber: true,
      },
      items: { ...navigationItemsConfig },
    },
  },
}
