import {
  DEFAULT_IMAGE_SQUARE,
  DEFAULT_MARKDOWN_PLACEHOLDER,
} from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  bgColorSchema,
  linkColorSchema,
  shadowSchema,
  showAllSettingsSchema,
  textColorSchema,
  titlePropertiesSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Speakers',
  description: 'Speakers block with popup',
  type: 'object',
  required: ['colsNumberOnDesktop', 'colsNumberOnMobile'],
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: [
      'bgColor',
      'blockShadow',
      'colsNumberOnDesktop',
      'colsNumberOnMobile',
      'visibleNumber',
      'isUseSliderOnMobile',
      'mainTitleColor',
      'titleColor',
      'subtitleColor',
      'linkColor',
      'modalBgColor',
      'modalTitleColor',
      'modalSubtitleColor',
      'textColor',
    ],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...showAllSettingsSchema,
    colsNumberOnDesktop: {
      title: 'Number of cols on desktop',
      type: 'number',
      default: 4,
      minimum: 1,
      maximum: 6,
    },
    colsNumberOnMobile: {
      title: 'Number of cols on mobile',
      type: 'number',
      default: 2,
      minimum: 1,
      maximum: 6,
    },
    visibleNumber: {
      title: 'How many speakers are visible',
      type: 'number',
      minimum: 1,
    },
    isUseSliderOnMobile: {
      title: 'Use slider on mobile',
      type: 'boolean',
      default: true,
    },
    ...shadowSchema,
    ...bgColorSchema,
    mainTitleColor: {
      ...textColorSchema.textColor,
      title: 'Block title color',
    },
    ...linkColorSchema,
    titleColor: {
      ...textColorSchema.textColor,
      title: 'Card title color',
    },
    subtitleColor: {
      ...textColorSchema.textColor,
      title: 'Card subtitle color',
    },
    textColor: {
      ...textColorSchema.textColor,
      title: 'Modal text color',
    },
    modalBgColor: {
      ...bgColorSchema.bgColor,
      title: 'Modal background color',
    },
    modalTitleColor: {
      ...textColorSchema.textColor,
      title: 'Modal title color',
    },
    modalSubtitleColor: {
      ...textColorSchema.textColor,
      title: 'Modal subtitle color',
    },
    speakers: {
      type: 'array',
      title: 'A list of speakers',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Speaker info',
        type: 'object',
        required: ['title', 'subtitle', 'image'],
        properties: {
          title: {
            title: 'Name',
            type: 'string',
            default: 'Name',
          },
          subtitle: {
            title: 'Subtitle',
            type: 'string',
            default: 'Job',
            'ui:options': {
              placeholder: 'Job',
              type: 'textarea',
              rows: 3,
            },
          },
          image: {
            title: 'Image url',
            type: 'string',
            default: DEFAULT_IMAGE_SQUARE,
          },
          text: {
            title: 'Additional info for popup',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
              type: 'textarea',
              rows: 6,
            },
          },
        },
      },
    },
  },
}
