<template>
  <div
    class="rounded-xl flex flex-col gap-5 p-5 pb-7"
    :style="{ 'background-color': bgColor }"
  >
    <div class="flex gap-3 items-center font-light italic">
      <div class="w-[60px]">
        <BaseRatio :ratio="1" class="w-full" size="cover">
          <div
            class="w-full h-full rounded-full overflow-hidden flex items-center justify-center"
          >
            <img :src="review.image" loading="lazy" class="w-full" />
          </div>
        </BaseRatio>
      </div>
      <div class="text-gray-900 break-words" :style="{ color: userInfoColor }">
        <div>
          {{ review.name }}
        </div>
        <div>
          {{ review.company }}
        </div>
      </div>
    </div>
    <div class="flex gap-3">
      <div class="w-[60px] shrink-0 hidden xl:block"></div>
      <div
        class="marked text-gray-400 break-words font-light"
        :style="{ color: textColor }"
        v-html="getMarkedTextWithBlankLinks(review.text, linkColor)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Review } from './types'

interface PropsInterface {
  review: Review
  userInfoColor: string
  textColor: string
  bgColor: string
  linkColor?: string
}

defineProps<PropsInterface>()

const { getMarkedTextWithBlankLinks } = useMarkedText()
</script>
