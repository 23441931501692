import type { Event } from './events'
import type { PaymentSystem } from './payment'
import type { Subscription } from './subscriptions'

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
}

export interface Ticket {
  id: number
  name: string
  subheading: string
  color: string
  hub_ticket_id: number
  main_currency: string
  future_price: number | null
  is_free: boolean
  price: {
    EUR: number
    USD: number
    RUB: number
    [key: string]: number
  }
}

export type TicketsRecord = Record<number, Ticket>

export interface TicketForUpgrade extends Ticket {
  is_ticket_of_user: boolean
  event_id: number
}

export type TicketsForUpgradeRecord = Record<number, TicketForUpgrade>

export interface TicketsOrderItem {
  entity_type: number
  entity_id: number
  quantity: number
}

export interface TicketsOrder {
  event_id: number
  currency: string
  is_request_invoice: boolean
  is_crypto_invoice: boolean
  promo_code?: string
  requisites?: string
  items: TicketsOrderItem[]
}

export enum OrderStatus {
  Created = 1,
  Done,
  Delivered,
  Canceled,
  Refund,
}

export const OrderStatusLocale = {
  [OrderStatus.Created]: 'tickets.order_status_created',
  [OrderStatus.Done]: 'tickets.order_status_done',
  [OrderStatus.Delivered]: 'tickets.order_status_delivered',
  [OrderStatus.Canceled]: 'tickets.order_status_canceled',
  [OrderStatus.Refund]: 'tickets.order_status_refund',
}

export enum OrderPaymentType {
  Cash = 1,
  Card,
  Invoice,
  Crypto,
  Free,
}

export const OrderPaymentTypeLocale = {
  [OrderPaymentType.Card]: 'tickets.order_payment_type_card',
  [OrderPaymentType.Cash]: 'tickets.order_payment_type_cash',
  [OrderPaymentType.Invoice]: 'tickets.order_payment_type_invoice',
  [OrderPaymentType.Crypto]: 'tickets.order_payment_type_crypto',
  [OrderPaymentType.Free]: 'tickets.order_payment_type_free',
}

export enum OrderType {
  Standard = 1,
  Free,
  Invoice,
  Gift,
  Sponsor,
  ByLink,
  Upgrade,
  Helper,
  Speaker,
  Partner,
  Winner,
}

export const OrderTypeLocale = {
  [OrderType.Standard]: 'tickets.order_type_standard',
  [OrderType.Free]: 'tickets.order_type_free',
  [OrderType.Invoice]: 'tickets.order_type_invoice',
  [OrderType.Gift]: 'tickets.order_type_gift',
  [OrderType.Sponsor]: 'tickets.order_type_sponsor',
  [OrderType.ByLink]: 'tickets.order_type_by_link',
  [OrderType.Upgrade]: 'tickets.order_type_upgrade',
  [OrderType.Helper]: 'tickets.order_type_helper',
  [OrderType.Speaker]: 'tickets.order_type_speaker',
  [OrderType.Partner]: 'tickets.order_type_partner',
  [OrderType.Winner]: 'tickets.order_type_winner',
}

export enum OrderItemActivationStatus {
  Created = 0,
  Activated = 1,
}

export interface TicketsOrderItemDetails {
  id?: number
  name: string
  subheading: string
  description?: string
  prices: {
    EUR: number
    USD: number
    RUB: number
    [key: string]: number
  }
}

interface TicketFeature {
  feature: string
  value: null
}

export type TicketDetails = {
  [key in Lowercase<Currency>]: number
} & {
  id: number
  name: string
  subtitle: null | string
  description: null | string
  paid: boolean // unknown business logic
  upgradable: boolean
  event_id: number
  eur: number
  rub: number
  usd: number
  future_price: null | number
  features: TicketFeature[]
}

export interface TicketsForUpgradeResponse {
  tickets_for_upgrade: TicketDetails[]
  current_ticket_currency: Currency
  current_ticket_id: number
}

export interface UserTicket extends TicketDetails {
  event_id: number
  event: Event
}

export interface TicketsOrderItemResponse {
  id: number
  entity_id: number
  entity_type: number
  entity_stamp?: any // TicketDetails but with prices * 100
  amount: number
  vat?: number
  email: string | null
  discount: number
  activation_status: OrderItemActivationStatus
  activation_date: string | null
  details: {
    ticket: TicketsOrderItemDetails
  }
}

export interface TicketsOrderResponse {
  id: number
  type: OrderType
  status: OrderStatus
  payment_type: OrderPaymentType
  user_id: number
  event_id: number
  wnconf_order_id: number
  wnconf_payment_url: string
  payment_link?: string
  amount: number
  vat?: number
  special_price?: null | number
  currency: Currency
  items: TicketsOrderItemResponse[]
  created_at: string
  promo_code_code: string
  promo_code_discount: number
  payment_system_type?: PaymentSystem
  payment_system_data?: any
}

export interface UpgradeRequestData {
  event_id: number
  currency: string
  promo_code?: string
  items: [
    {
      entity_type: number
      entity_id: number
    }
  ]
}

export interface Payment {
  id: number
  amount: number
  created_at: string
  currency: Currency
  transaction_id: number
  subscription: Subscription | null
  order: TicketsOrderResponse | null
}
