<template>
  <div>
    <NuxtLayout>
      <!-- TODO: https://app.asana.com/0/1202924432057564/1203829013202626 -->
      <!-- <NuxtLoadingIndicator color="#0D65D8" :height="2" /> -->
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import './patch-server-log'
import { useI18n } from 'vue-i18n'
import { setLocale } from '@vee-validate/i18n'
import { Preferences } from '@capacitor/preferences'
import { useAuth } from '~/stores/auth'
import { useUsers } from '~/stores/users'
// import { useChat } from '~/stores/chat'
import { useCurrentLang } from '~/stores/current-lang'

const { $dayjs } = useNuxtApp()
const { locale, t } = useI18n()
const { setLang } = useCurrentLang()
const authStore = useAuth()
const usersStore = useUsers()
// const chatStore = useChat()
const { isApp } = useDetect()
const CapacitorLangKey = 'lang'

// we want to check and load profile here as sometimes we need failed to load it from middleware
if (authStore.auth && !authStore.profile) {
  await authStore.fetchProfile()
}

authStore.setSentryUser()

if (useDetect().isInIframe) {
  console.log('auth: request auth from parent frame')
  window.parent &&
    window.parent.postMessage({ message_type: 'auth-request' }, '*')
}

// for some reason sometimes (Safari iOS) thirdparty widgets breaks safe-area-inset-top env css variables
// so we save them into custom properties
function resaveCssEnv() {
  const variables = ['--sat', '--sab']

  variables.forEach((variable) => {
    const satValue = getComputedStyle(
      document.documentElement
    ).getPropertyValue(variable)

    document.documentElement.style.setProperty(variable, satValue)
  })
}

process.client && resaveCssEnv()

await authStore.updateProfileTimezone()

// TODO: uncomment on complete chat integration
// watch(
//   () => authStore.auth,
//   () => {
//     if (process.server) {
//       return
//     }
//     if (authStore.auth) {
//       chatStore.initChat()
//     } else {
//       chatStore.resetChat()
//     }
//   },
//   { immediate: true }
// )

if (isApp) {
  try {
    const appLang = (await Preferences.get({ key: CapacitorLangKey })).value

    if (appLang && appLang !== locale.value) {
      onNuxtReady(() => {
        useSetLanguage(appLang)
      })
    }
  } catch (error: any) {
    useLogError(error)
  }
  document.body.classList.add('is-app')
}

onNuxtReady(() => {
  console.log('nuxt: ready', performance.now() / 1000)
  document.body.classList.add('nuxt-ready')
})

setLang(locale.value)

watch(
  () => locale.value,
  async () => {
    setLang(locale.value)
    $dayjs.locale(locale.value)
    setLocale(locale.value)
    isApp && Preferences.set({ key: CapacitorLangKey, value: locale.value })
    await usersStore.reloadUser()
  }
)

useHeadMeta({
  title: () => t('head_meta.default_title'),
  description: () => t('head_meta.default_description'),
  keywords: () => t('head_meta.default_keywords'),
  image: '/og-image.png',
})
</script>
