<template>
  <div
    :id="uuid"
    class=""
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
    }"
  >
    <div class="flex flex-wrap gap-6 lg:flex-nowrap">
      <template v-for="(banner, index) in formData.banners" :key="index">
        <div
          class="flex flex-1 px-6 md:px-8 rounded-xl"
          :class="[
            flexAlignClass(banner),
            paddingClass(banner),
            formData.blockShadow && 'shadow-lg',
          ]"
          :style="{
            background:
              banner.isBgImage && banner.bgImage
                ? `center / cover no-repeat url(${banner.bgImage})`
                : undefined,
            color: banner.textColor,
            backgroundColor: banner.bgColor,
          }"
        >
          <div class="md:w-1/2 flex flex-col gap-4 md:gap-8">
            <div
              class="marked"
              :class="[`text-${banner.textAlign}`]"
              v-html="
                getMarkedTextWithBlankLinks(banner.text, banner.linkColor)
              "
            />
            <div
              v-if="banner.buttons?.length"
              class="flex flex-wrap gap-4"
              :class="flexAlignClass(banner)"
            >
              <template v-for="(button, idx) in banner.buttons" :key="idx">
                <EditorButton :button="button" />
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ButtonInterface } from '../common'
import { usePageEditor } from '~/stores/page-editor'
import EditorButton from '~/components/page-editor/EditorButton.vue'

const pageEditorStore = usePageEditor()

interface Banner {
  text: string
  textColor?: string
  linkColor?: string
  textAlign?: 'left' | 'center' | 'right'
  paddingSize: 'xs' | 'sm' | 'md' | 'lg'
  isBgImage: boolean
  bgImage?: string
  bgColor?: string
  buttons: ButtonInterface[]
}

interface FormDataInterface {
  banners: Banner[]
  blockShadow?: boolean
}

interface PropsInterface {
  uuid: string
  formData: FormDataInterface
}

defineProps<PropsInterface>()
const { getMarkedTextWithBlankLinks } = useMarkedText()

const flexAlignClass = (banner: Banner) => {
  switch (banner.textAlign) {
    case 'left':
      return 'justify-start'
    case 'center':
      return 'justify-center'
    case 'right':
      return 'justify-end'
    default:
      return 'justify-center'
  }
}

const paddingClass = (banner: Banner) => {
  switch (banner.paddingSize) {
    case 'xs':
      return 'py-6 md:py-8'
    case 'sm':
      return 'py-14 md:py-20'
    case 'lg':
      return 'py-32 md:py-56'
    default:
      return 'py-20 md:py-40'
  }
}
</script>
