import { basePropertiesSchema } from '../base-schema-properties'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Expo',
  description: 'Expozone interactive map',
  type: 'object',
  required: ['image'],
  properties: {
    ...basePropertiesSchema,
    blockHeight: {
      title: 'Block height',
      type: 'number',
      default: 400,
      minimum: 200,
      maximum: 2000,
    },
    image: {
      title: 'SVG url',
      type: 'string',
    },
  },
}
