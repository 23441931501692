<template>
  <div v-if="eventsStore.event" class="flex flex-col gap-5">
    <div v-if="!hideImage" class="relative">
      <BaseRatio
        class="rounded-xl md:hidden"
        :ratio="1 / 2"
        size="cover"
        :src="
          eventsStore.event.logo?.url ||
          '/event-default-background-2400x1260.png'
        "
      />
      <BaseRatio
        class="rounded-xl max-md:hidden"
        :ratio="5 / 13"
        size="cover"
        :src="
          eventsStore.event.background?.url ||
          '/event-default-background-2400x1260.png'
        "
      />
      <div v-if="authStore.auth" class="absolute top-3 right-3">
        <FavoritesButton
          :entity-title="eventTitle"
          :entity-id="eventsStore.event.id"
          :entity-type="EntityType.Event"
          button-class="text-primary-900 bg-primary-100 hover:text-primary-900 hover:bg-primary-200 active:bg-primary-200 h-8 px-4"
        />
      </div>
    </div>
    <div class="flex gap-5 flex-wrap justify-between">
      <div class="flex flex-col gap-2">
        <h1 v-if="!hideTitle" class="font-bold text-3xl mb-2">
          {{ eventTitle }}
        </h1>
        <div
          v-if="!hideDates"
          class="flex flex-col xs:flex-row flex-wrap gap-x-4 gap-y-2 xs:items-center"
        >
          <div class="flex gap-2">
            <BaseIcon
              name="outline_calendar"
              size="xs"
              title="Date"
              class="mt-[2px] flex-shrink-0"
            />
            <div class="text-sm">
              {{
                getLocalCustomDate(eventsStore.event.custom_date) ||
                getHumanizedDatePeriod(
                  eventsStore.event.date_from,
                  eventsStore.event.date_to,
                  eventsStore.event.timezone
                )
              }}
            </div>
          </div>
          <BaseLink
            v-if="googleCalendarLink && !hideGoogleCalendar"
            class="flex gap-2 items-center"
            :href="googleCalendarLink"
            target="_blank"
          >
            <BaseIcon
              name="google_calendar"
              size="none"
              :title="$t('events.add_to_google_calendar')"
              class="w-[18px] h-[19px] flex-shrink-0"
            />
            <div class="text-sm">
              {{ $t('events.add_to_google_calendar') }}
            </div>
          </BaseLink>
        </div>
        <div v-if="hubDates && !hideHubDates" class="flex gap-2">
          <BaseIcon
            name="outline_chat_alt_2"
            size="xs"
            title="online_platform"
            class="mt-[2px] flex-shrink-0"
          />
          <div class="text-sm">
            {{ $t('events.online_platform_availability') + ' ' }}
            <span class="whitespace-nowrap">{{ hubDates }}</span>
          </div>
        </div>
        <div v-if="!hidePlace">
          <component
            :is="eventPlaceLink ? BaseLink : BaseFragment"
            class="inline-flex"
            :href="eventPlaceLink"
            target="_blank"
          >
            <span class="inline-flex gap-2">
              <BaseIcon
                name="outline_map_pin"
                size="xs"
                title="place"
                class="mt-[2px] flex-shrink-0"
              />
              <span class="text-sm">
                {{ eventPlace }}
              </span>
            </span>
          </component>
        </div>
      </div>
      <div
        class="flex gap-2 flex-wrap items-start flex-grow md:flex-grow-0 w-full md:w-auto"
      >
        <div
          v-if="isShowOnlinePlatformButton"
          class="flex flex-col gap-1 flex-grow md:flex-grow-0"
        >
          <BaseLink
            :href="getHubLink(eventsStore.event.slug)"
            :class="!isHubOpen && 'pointer-events-none'"
            :target="isApp ? '_self' : '_blank'"
          >
            <BaseButton
              look="solid"
              :theme="isHubOpen ? 'primary' : 'disabled'"
              rounded="xl"
              size="md"
              class="w-full"
              :disabled="!isHubOpen"
            >
              <div>
                {{ onlinePlatformButtonText }}
              </div>
            </BaseButton>
          </BaseLink>
          <div
            v-if="ticketsToActivateCount"
            class="flex items-center justify-center gap-2 text-pro hover:text-pro-300 pb-4 md:pb-0"
          >
            <BaseIcon name="outline_exclamation_thin" size="sm" />
            <BaseLocaleLink to="/users/me/orders">
              <i18n-t keypath="tickets.tickets_left_to_activate" tag="div">
                <template #count>
                  {{ ticketsToActivateCount }}
                </template>
                <template #ticket>
                  <span class="underline">
                    {{
                      $t('tickets.tickets_forms', {
                        count: ticketsToActivateCount,
                      })
                    }}
                  </span>
                </template>
              </i18n-t>
            </BaseLocaleLink>
          </div>
        </div>

        <template v-if="isShowBuyTicketButton">
          <BaseButton
            v-if="hashToScrollForRegistration"
            look="solid"
            theme="primary"
            rounded="xl"
            class="flex-grow md:flex-grow-0"
            size="md"
            @click="scrollToHash(hashToScrollForRegistration)"
          >
            <BaseIcon
              name="outline_ticket"
              class="flex-shrink-0 mr-3"
              size="xs"
            />
            {{ $t('events.register') }}
          </BaseButton>
          <BaseLink
            v-else
            class="flex-grow md:flex-grow-0"
            :href="eventsStore.event.config?.login?.registrationUrl"
            target="_blank"
          >
            <BaseButton
              look="solid"
              theme="primary"
              rounded="xl"
              class="w-full"
              size="md"
            >
              <BaseIcon
                name="outline_ticket"
                class="flex-shrink-0 mr-3"
                size="xs"
              />
              {{ $t('events.register') }}
            </BaseButton>
          </BaseLink>
        </template>

        <BaseButton
          v-if="!authStore.auth && isHubOpen"
          class="flex-grow md:flex-grow-0"
          look="ghost"
          theme="info"
          rounded="xl"
          size="md"
          @click="authStore.openLoginModal"
        >
          {{ $t('events.login_to_access') }}
        </BaseButton>

        <BaseButton
          look="ghost"
          theme="info"
          rounded="xl"
          class="flex-grow md:flex-grow-0"
          size="md"
          @click="openShareModal"
        >
          <BaseIcon name="outline_share" class="flex-shrink-0 mr-3" size="xs" />
          {{ $t('common.share') }}
        </BaseButton>
      </div>
      <ShareModal
        :is-open="isShareModalOpen"
        :share-options="getEventShareOptions(eventLink, eventsStore.event.name)"
        :link="eventLink"
        @close="closeShareModal"
      />
    </div>
    <!-- <div v-if="eventsStore.isEventActual(eventsStore.event)">
      <BaseButton
        class="w-full md:w-auto"
        :look="
          reactionsCounters[ReactionType.WantToGo]?.is_set_by_user
            ? 'solid'
            : 'border'
        "
        theme="primary"
        rounded="xl"
        :loading="isCountersProcessing"
        size="md"
        @click="onChangeReaction(ReactionType.WantToGo)"
      >
        <BaseIcon name="outline_star" class="flex-shrink-0 mr-3" size="xs" />
        {{ $t('events.want_to_go') }}
      </BaseButton>
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useEvents } from '~/stores/events'
import { useAuth } from '~/stores/auth'
import { EntityType } from '~/models/common'
import { EventType, EventsWithoutCalendarButton } from '~/models/events'
import { useTickets } from '~/stores/tickets'
import { useNotifications } from '~/stores/notifications'

const props = defineProps({
  hideImage: {
    type: Boolean,
    default: false,
  },
  hideTitle: {
    type: Boolean,
    default: false,
  },
  hideDates: {
    type: Boolean,
    default: false,
  },
  hideGoogleCalendar: {
    type: Boolean,
    default: false,
  },
  hideHubDates: {
    type: Boolean,
    default: false,
  },
  hidePlace: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['loaded'])

const { pushNotification } = useNotifications()
const BaseLink = resolveComponent('BaseLink')
const BaseFragment = resolveComponent('BaseFragment')

const eventsStore = useEvents()
const {
  getEventPageRoute,
  getEventPageLink,
  getEventShareOptions,
  getLocalCustomDate,
  getHubLink,
  getEventPlace,
  getEventPlaceLink,
  fetchEvent,
  resetEvent,
  isEventFuture,
} = eventsStore
const route = useRoute()
const authStore = useAuth()
const { t } = useI18n()
const { isApp } = useDetect()
const ticketsStore = useTickets()
const {
  countOrderItemsToActivate,
  fetchOrdersByEventId,
  resetUserOrdersByEventId,
} = ticketsStore
const { getHumanizedDatePeriod, getHumanizedDate, parseDate } = useDate()
const { getMarkedTextWithBlankLinks } = useMarkedText()

const eventSlug = route.params.event as string

const eventLink = computed(() => {
  return getEventPageLink(getEventPageRoute(eventSlug, eventsStore.event?.type))
})

const isShareModalOpen = ref(false)
const isLoaded = ref(false)

const openShareModal = () => {
  isShareModalOpen.value = true
}

const closeShareModal = () => {
  isShareModalOpen.value = false
}

function scrollToHash(hash: string) {
  const element = document.querySelector(hash)
  if (!element) {
    pushNotification({
      title: 'No block with such hash on the page',
      theme: 'destructive',
    })
    return
  }
  element.scrollIntoView({ behavior: 'smooth' })
}

const hashToScrollForRegistration = computed(() => {
  const registrationUrl = eventsStore.event?.config?.login?.registrationUrl

  const currentPath = useRoute().path

  if (!registrationUrl) {
    return null
  }

  try {
    const url = new URL(registrationUrl)

    if (
      url.host === 'wnhub.io' &&
      currentPath.includes(url.pathname) &&
      url.hash
    ) {
      return url.hash
    }
  } catch (e: any) {
    return null
  }
  return null
})

const eventTitle = computed<string>(
  () => eventsStore.event?.tagline || eventsStore.event?.name || ''
)

const hubDates = computed(() => {
  if (
    eventsStore.event &&
    eventsStore.event.hub_date_from &&
    eventsStore.event.hub_date_to
  ) {
    return getHumanizedDatePeriod(
      eventsStore.event.hub_date_from,
      eventsStore.event.hub_date_to,
      eventsStore.event.timezone
    )
  }
  return ''
})

const eventPlace = computed(() => {
  return eventsStore.event ? getEventPlace(eventsStore.event) : ''
})

const eventPlaceLink = computed(() => {
  return eventsStore.event ? getEventPlaceLink(eventsStore.event) : ''
})

const isHubOpen = computed(() =>
  eventsStore.event ? eventsStore.isHubOpen(eventsStore.event) : false
)

const isShowOnlinePlatformButton = computed(
  () =>
    !!authStore.auth &&
    (!!eventsStore.event?.is_has_ticket || !!eventsStore.event?.auto_reg)
)

const isShowBuyTicketButton = computed(
  () =>
    !!eventsStore.event &&
    eventsStore.isEventActual(eventsStore.event) &&
    !!eventsStore.event?.config?.login?.registrationUrl &&
    !eventsStore.event?.is_has_ticket
)

const onlinePlatformButtonText = computed(() => {
  if (!eventsStore.event) {
    return ''
  }

  // if event is in future
  if (isEventFuture(eventsStore.event)) {
    const date = getHumanizedDate(
      eventsStore.event.hub_date_from || eventsStore.event.date_from,
      eventsStore.event.timezone
    )

    return `${t('events.available_from')} ${date}`
  }

  // if event is open
  if (isHubOpen.value) {
    return t('events.join')
  }

  // if event and hub are closed
  if (
    !isHubOpen.value &&
    (eventsStore.event.hub_date_to || eventsStore.event.date_to)
  ) {
    const date = getHumanizedDate(
      eventsStore.event.hub_date_to || eventsStore.event.date_to,
      eventsStore.event.timezone
    )

    return `${t('events.finished_on')} ${date}`
  }

  return t('events.finished')
})

const ticketsToActivateCount = computed(() => {
  return ticketsStore.ordersByEventId.reduce((acc, order) => {
    const orderCount = countOrderItemsToActivate(order)
    acc += orderCount
    return acc
  }, 0)
})

const GoogleCalendarLinkLabel: Record<EventType, string> = {
  [EventType.Default]: t('events.event_page'),
  [EventType.Course]: t('events.course_page'),
}

const googleCalendarLink = computed<string>(() => {
  if (
    !eventsStore.event ||
    EventsWithoutCalendarButton.includes(eventSlug) ||
    props.hideGoogleCalendar
  ) {
    return ''
  }

  const url = new URL('https://www.google.com/calendar/render')
  url.searchParams.set('action', 'TEMPLATE')
  url.searchParams.set('text', eventTitle.value)
  url.searchParams.set('details', googleCalendarDescription.value)
  url.searchParams.set(
    'location',
    eventsStore.event.full_address ||
      eventsStore.event.address ||
      eventsStore.event.city ||
      ''
  )

  const startDate = formatGoogleCalendarDate(eventsStore.event.date_from)

  const endDate = formatGoogleCalendarDate(eventsStore.event.date_to)

  url.searchParams.set(
    'dates',
    startDate && endDate && `${startDate}/${endDate}`
  )

  return url.toString()
})

const googleCalendarDescription = computed(() => {
  if (!eventsStore.event) {
    return ''
  }

  const description =
    getMarkedTextWithBlankLinks(eventsStore.event?.description || '') ||
    eventsStore.event?.preview_description ||
    ''

  const link = eventLink.value
  const linkLabel = GoogleCalendarLinkLabel[eventsStore.event.type]
  const linkText = `${linkLabel}: <a href="${link}">${link}</a>`

  return [description, linkText].filter((text) => text).join('\n\n')
})

function formatGoogleCalendarDate(date: string): string {
  const parsedDate = parseDate(date, 'UTC')

  return parsedDate && parsedDate.format('YYYYMMDDTHHmmss[Z]')
}

const load = async () => {
  isLoaded.value = false
  await fetchEvent(eventSlug, !!authStore.auth)
  isLoaded.value = true

  if (!eventsStore.event) {
    return
  }

  if (authStore.auth) {
    await fetchOrdersByEventId(eventsStore.event.id)
  }
}

onMounted(async () => {
  await load()
  emit('loaded')
})

onServerPrefetch(async () => {
  await load()
})

watch(
  () => !!authStore.auth,
  async () => {
    resetEvent()
    resetUserOrdersByEventId()

    await load()
  }
)
</script>
