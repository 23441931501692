<template>
  <div ref="programSectionElement" class="section">
    <div class="flex">
      <div class="w-full">
        <div
          class="flex flex-col md:flex-row px-5 py-6 rounded-2xl"
          :class="isSpecialSection ? 'bg-gray-100' : 'bg-gray-80'"
        >
          <div
            class="flex-shrink-0 flex-wrap gap-1 items-start justify-between w-full md:w-28 mb-4 md:mb-0 md:mr-6 md:space-y-4 flex flex-row md:flex-col"
            :class="
              isSpecialSection && section.is_buttons_hidden && 'md:self-center'
            "
          >
            <div v-if="!section.is_time_hidden" class="font-medium text-base">
              <span class="whitespace-nowrap">
                {{ parseTime(section.start) }}
              </span>
              -
              <span class="whitespace-nowrap">
                {{ parseTime(section.end) }}
              </span>
            </div>
            <div class="inline-flex md:hidden">
              <ProgramSectionBadge
                :color="badgeColor"
                :label="ProgramSectionTypesLabelsMap[section.type]"
                :is-special-section="isSpecialSection"
              />
            </div>
            <button
              v-if="!section.is_buttons_hidden"
              title="Add to the calendar"
              class="hover:opacity-70 hidden md:block"
              @click="emit('add-to-calendar')"
            >
              <BaseIcon
                name="program_google_calendar"
                size="none"
                class="w-12 h-12"
              />
            </button>
          </div>

          <div class="flex gap-2 flex-grow">
            <div class="flex flex-col flex-grow">
              <div
                class="section-title flex text-base"
                :class="isSpecialSection ? 'font-medium' : 'font-bold'"
              >
                <div class="hidden md:block flex-shrink-0">
                  <ProgramSectionBadge
                    :color="badgeColor"
                    :label="ProgramSectionTypesLabelsMap[section.type]"
                    :is-special-section="isSpecialSection"
                  />
                </div>

                <div v-html="getMarkedTextWithBlankLinks(section.title)" />

                <div
                  v-if="isSpecialSection && sponsors.length > 0"
                  class="font-bold pl-1"
                >
                  – Sponsored by {{ sponsors.join(', ') }}
                </div>
              </div>

              <div
                v-if="section.description && isDescriptionOpen"
                class="section-description w-full max-w-none prose prose-sm"
              >
                <div
                  v-html="getMarkedTextWithBlankLinks(section.description)"
                />
              </div>

              <div
                v-if="!isSpecialSection && section.participants.length"
                class="mt-4 grid grid-cols-1 gap-x-9 gap-y-4"
                :class="section.participants.length > 1 && 'md:grid-cols-2'"
              >
                <template
                  v-for="(participant, index) in section.participants"
                  :key="index + participant.type + participant.entity_type"
                >
                  <div class="flex items-start">
                    <div
                      class="p-0.5 my-0.5 mr-4 flex-shrink-0 bg-white shadow rounded-full"
                    >
                      <div class="w-9 h-9 rounded-full overflow-hidden">
                        <img
                          v-if="participant.entity.media[0]?.url"
                          class="w-full h-full object-cover object-center"
                          :src="participant.entity.media[0].url"
                          :srcset="`${
                            participant.entity.media[0].lr ||
                            participant.entity.media[0].url
                          } 1x, ${
                            participant.entity.media[0].hr ||
                            participant.entity.media[0].url
                          } 1.1x`"
                        />
                        <div
                          v-else
                          class="w-full h-full flex justify-center items-center text-base font-bold text-white"
                          :class="
                            isOnGeneral
                              ? 'bg-primary-900'
                              : 'avatar-empty-content'
                          "
                        >
                          {{ getParticipantInitials(participant) }}
                        </div>
                      </div>
                    </div>

                    <div v-if="instanceOfProgramUser(participant.entity)">
                      <div
                        class="flex flex-wrap items-start md:items-center flex-col md:flex-row font-semibold text-base"
                      >
                        <span
                          v-if="ProgramParticipantTypesLabels[participant.type]"
                          class="inline-flex items-center capitalize text-blue-600 text-xs rounded-full border border-blue-600 px-2 md:mr-1.5 mb-px md:mb-0 py-0.5"
                        >
                          {{ ProgramParticipantTypesLabels[participant.type] }}
                        </span>
                        <span
                          :class="
                            isParticipantHasLink(participant)
                              ? 'cursor-pointer hover:opacity-70'
                              : 'pointer-events-none'
                          "
                          @click="onParticipantClick(participant)"
                        >
                          {{ getParticipantName(participant) }}
                        </span>
                      </div>

                      <div class="text-sm opacity-70">
                        <span class="hidden md:inline">
                          {{ getParticipantJob(participant) }}
                        </span>
                        <span class="md:hidden">
                          {{ participant.entity.company_name }}
                        </span>
                      </div>
                    </div>

                    <div
                      v-else-if="instanceOfProgramCompany(participant.entity)"
                    >
                      <div class="text-sm opacity-70">Sponsored By</div>
                      <div
                        class="font-semibold text-base"
                        :class="
                          isParticipantHasLink(participant)
                            ? 'cursor-pointer hover:opacity-70'
                            : 'pointer-events-none'
                        "
                        @click="onParticipantClick(participant)"
                      >
                        {{ participant.entity.name }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <div
              class="flex flex-col justify-between items-center gap-4 flex-shrink-0"
            >
              <ProgramSectionIcon
                :section="section"
                :is-description-open="isDescriptionOpen"
                @toggle-description="toggleDesscription"
              />
              <FavoritesButton
                v-if="isShowFavoriteButton"
                icon-width="thin"
                :entity-title="section.title"
                :entity-id="section.id"
                :initial-value="isFavorite"
                :entity-type="EntityType.ProgramSection"
                :event-id="eventId"
                :is-loading="isFavoriteLoading"
                :button-class="
                  isOnGeneral
                    ? 'text-black hover:text-primary-900 p-1'
                    : 'text-black favorite-button p-1'
                "
                :active-button-class="
                  isOnGeneral
                    ? 'text-primary-900 hover:opacity-80'
                    : 'favorite-button-active'
                "
                icon-size="md"
                @update="(...args) => emit('update:favorite', ...args)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ProgramSectionBadge from './ProgramSectionBadge.vue'
import ProgramSectionIcon from './ProgramSectionIcon.vue'
import type {
  ProgramSection,
  ProgramTemplate,
  ProgramParticipant,
} from '~/models/program'
import {
  SpecialSections,
  instanceOfProgramUser,
  instanceOfProgramCompany,
  ProgramParticipantTypes,
} from '~/models/program'
import { useProgram } from '~/stores/program'
import { useAuth } from '~/stores/auth'
import { EntityType } from '~/models/common'

interface PropsInterface {
  section: ProgramSection
  timezone: string
  template: ProgramTemplate
  isOnHub: boolean
  isOnGeneral: boolean
  isInIframe: boolean
  isUs: boolean
  isHubOpen: boolean
  dayDate: string
  eventId: number
}

const props = withDefaults(defineProps<PropsInterface>(), {})

const emit = defineEmits([
  'add-to-calendar',
  'open-entity',
  'update',
  'update:favorite',
])

onUpdated(() => {
  nextTick(() => emit('update'))
})

const { ProgramSectionTypesLabelsMap } = useProgram()
const { $dayjs } = useNuxtApp()
const { getMarkedTextWithBlankLinks } = useMarkedText()
const authStore = useAuth()

const isDescriptionOpen = ref(false)

const isSpecialSection = computed(() =>
  SpecialSections.includes(props.section.type)
)

// dates in program are in the program timezone, not in utc
const parseTime = (time: string) => {
  return $dayjs
    .tz(`${props.dayDate} ${time}`, props.timezone)
    .format(props.isUs ? 'h:mm A' : 'HH:mm')
}

const badgeColor = computed(() => {
  return props.template.params.badgeColors.find(
    (item) => item.sectionTypeId === props.section.type
  )?.color
})

const isShowFavoriteButton = computed(() => {
  return !!authStore.auth && (!props.isInIframe || props.isOnHub)
})

const getParticipantName = (participant: ProgramParticipant) => {
  if (instanceOfProgramUser(participant.entity)) {
    return `${participant.entity.first_name} ${participant.entity.last_name}`
  } else if (instanceOfProgramCompany(participant.entity)) {
    return participant.entity.name
  }

  return ''
}

const getParticipantJob = (participant: ProgramParticipant) => {
  if (
    'job_title' in participant.entity &&
    'company_name' in participant.entity &&
    participant.entity.job_title &&
    participant.entity.company_name
  ) {
    return `${participant.entity.job_title}, ${participant.entity.company_name}`
  } else if (
    'job_title' in participant.entity &&
    participant.entity.job_title
  ) {
    return participant.entity.job_title
  } else if (
    'company_name' in participant.entity &&
    participant.entity.company_name
  ) {
    return participant.entity.company_name
  }

  return ''
}

const getParticipantInitials = (participant: ProgramParticipant) => {
  if (instanceOfProgramUser(participant.entity)) {
    return getAbbr(participant.entity)
  } else if (instanceOfProgramCompany(participant.entity)) {
    return participant.entity.name.slice(0, 1)
  }
  return ''
}

const ProgramParticipantTypesLabels: Partial<
  Record<ProgramParticipantTypes, string>
> = {
  [ProgramParticipantTypes.Moderator]: 'Moderator',
  [ProgramParticipantTypes.WnAcademyExpert]: 'WN Academy Expert',
}

const onParticipantClick = (participant: ProgramParticipant) => {
  emit(
    'open-entity',
    instanceOfProgramUser(participant.entity) ? 'user' : 'company',
    participant.entity.href_type,
    participant.entity.href_id,
    participant.entity.href_link
  )
}

const isParticipantHasLink = (participant: ProgramParticipant) => {
  if (!props.isHubOpen) {
    return false
  }

  if (props.isOnHub || props.isOnGeneral) {
    return !!(participant.entity.href_id || participant.entity.href_link)
  } else if (participant.entity.href_type === 'outer') {
    return !!participant.entity.href_link
  }

  return false
}

const sponsors = computed(() => {
  return props.section.participants
    .filter(
      (participant) => participant.type === ProgramParticipantTypes.Sponsor
    )
    .map((participant) => getParticipantName(participant))
})

const accentColor = computed(() => {
  return (
    (props.isOnHub
      ? props.template.params.accentColor
      : props.template.params.hub.accentColor) || undefined
  )
})

const toggleDesscription = () => {
  isDescriptionOpen.value = !isDescriptionOpen.value
}

const { initCountersHelper, isFavorite, isFavoriteLoading } =
  useCountersHelper()

const programSectionElement = ref(null)
const entity = {
  entity_id: props.section.id,
  entity_type: EntityType.ProgramSection,
}

async function init() {
  await initCountersHelper({
    entity,
    target: programSectionElement,
    isLoadComments: false,
    isLoadReactions: false,
    isCheckFavorite: !!authStore.auth,
  })
}

watch(
  () => authStore.auth,
  async () => {
    await init()
  }
)

onMounted(async () => {
  await init()
})
</script>

<style scoped>
.section-title:deep() div p {
  display: inline;
}

.section-title:deep() div a {
  text-decoration: underline;
}

.section-title:deep() div a:hover {
  text-decoration: none;
}

.section-description:deep() p {
  margin-bottom: 0;
}

.section-description:deep() a:hover {
  text-decoration: none;
}

.avatar-empty-content {
  background-color: v-bind(accentColor);
}

.favorite-button:hover {
  color: v-bind(accentColor);
}

.favorite-button-active {
  color: v-bind(accentColor);
}
</style>
