import type { Breadcrumb, BreadcrumbHint } from '@sentry/types'
import { Sentry } from '~/utils/import-sentry'
import * as pkg from '~/package.json'
import { useSkipKey } from '~/composables/use-create-screen-error'

export default defineNuxtPlugin((nuxtApp) => {
  const release = `wnhub-general@${pkg.version}`
  const dsn = nuxtApp.$config.public.sentryDsn
  const { vueApp } = nuxtApp
  const { isProd } = useDetect()

  // Sentry successfully ignores the same error on its side
  function processEcxeption(error: any, handler: string) {
    console.error('processEcxeption', handler, error)
    if (error.message?.includes(useSkipKey()) || !dsn) {
      console.warn('Skip error from capture', error)

      return
    }
    Sentry.captureException(error, {
      tags: {
        handler,
      },
    })

    // show error page on mobile to force user to reload the app
    const { isApp } = useDetect()
    if (isApp) {
      console.log('app: show error screen')
      // for some reason createError with fatal flang doesn't trigger error page here (from plugins?)
      // throw useCreateScreenError({ statusCode: error.status || 500 })
      showError({
        statusCode: error.status || 500,
        message: 'app error; ' + useSkipKey(),
      })
    }
  }

  // Sentry mutes some errors event with logErrors: true so we init it explicitly
  dsn &&
    isProd &&
    Sentry.init({
      app: [vueApp],
      dsn,
      release,
      logErrors: true,
      beforeBreadcrumb(
        breadcrumb: Breadcrumb,
        hint: BreadcrumbHint | undefined
      ) {
        if (breadcrumb.category === 'fetch' && hint) {
          const { input } = hint
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_url, request] = input || []
          const { body } = request || {}

          if (body && breadcrumb.data) {
            breadcrumb.data.body = body
          }
        }
        return breadcrumb
      },
      // beforeSend(event: any, hint: any) {
      //   console.log('on before send', event, hint)

      //   return null
      // },
      // TODO: enable performance monitoring in the bright future
      // integrations: [new Integrations.BrowserTracing()],
      // sampleRate: 0.1,
      // tracesSampleRate: 0.1,
    })

  nuxtApp.hook('vue:error', (error: any) => {
    processEcxeption(error, 'vue:error')
  })

  nuxtApp.hook('app:error', (error: any) => {
    processEcxeption(error, 'app:error')
  })

  nuxtApp.vueApp.config.errorHandler = (error: any) => {
    processEcxeption(error, 'config:errorHandler')
  }

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
