import { basePropertiesSchema } from '../base-schema-properties'
import { shadowSchema, titlePropertiesSchema, titleSizeSchema } from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Featured Events',
  description: 'Block with list of events',
  type: 'object',
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    title: ['titleSize', 'titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    ...titleSizeSchema,
    ...shadowSchema,
    viewAllLink: {
      title: 'View all link (only internal link, for example: /event-info)',
      type: 'string',
      default: '/event-info',
    },
    viewAllText: {
      title: 'View all text',
      type: 'string',
      default: 'View all',
    },
    events: {
      type: 'array',
      title: 'A list of events',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Event',
        type: 'object',
        required: ['eventId'],
        properties: {
          eventId: {
            title: 'Event Id',
            type: 'number',
          },
        },
      },
    },
  },
}
